import React, { useEffect } from "react";
import PublicLayout from "../components/PublicLayout";
import SEO from "../components/seo";
import { Link, graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Layout } from 'antd';
import imageUrlReplace from "./components/blog/imageUrlReplace";

const { Content } = Layout;

const ServicesLanding = ({ pageContext }) => {
  const {
    type,
    serviceLanding: {
      id,
      meta_tag: {
        metaTitle,
        metaDescription,
      },
      fields: {
        title,
        subtitle,
        cardOne,
        cardTwo,
        cardThree,
        contactMotive,
        image,
      }
    }
  } = pageContext;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }];

  const servicesCards = () => {
    const breakpointValue = cardThree ? 8 : 12;
    const xGutter = cardThree ? 40 : 16;
    const cards = [cardOne, cardTwo];

    if (cardThree) {
      cards.push(cardThree);
    }

    return (
      <Row gutter={[xGutter, 30]}>
      {
        cards.map((item, index) => {
          return (
            <Col lg={breakpointValue} xs={24} key={index}>
              <Row
                justify="center"
                className="card-item"
                align="stretch"
              >
                <div dangerouslySetInnerHTML={{ __html: item && item}} />
              </Row>
            </Col>
          )
        })
      }
      </Row>
    );
  };


  const scrollToElement = () => {
    const element = document.getElementById('form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (type && type === 'success') {
      setTimeout(() => {
        scrollToElement();
      }, 1800);
    }
  }, [type]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src='//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    let formId;

    if (contactMotive === 'health_company_service') {
      formId = '15552956-f347-46ec-ac98-2f53d04551a6';
    } else {
      formId = '042231ed-126b-43be-ad15-707323bc2b42';
    }

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "44540453",
          formId,
          target: '#hubspotForm',
        });
      }
    });
  }, [contactMotive]);


  return (
    <PublicLayout>
      <SEO
        title={
          type === 'form' ?
            metaTitle
          :
            (contactMotive === 'health_company_service' ? t('seo.contact.titleSuccessHealth') : t('seo.contact.titleSuccessTerrain'))
        }
        description={
          type === 'form' ?
            metaDescription
          :
            (contactMotive === 'health_company_service' ? t('seo.contact.descriptionSuccessHealth') : t('seo.contact.descriptionSuccessTerrain'))
        }
        dataLayer={{
          userId: "44",
          pageId: id && id,
          pageType: "page",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className='service-landing-container'>
        <Content>
          <Row>
            <Col lg={24} xs={24}>
              <Row justify="center" className='title-image-wrapper' align='stretch'>
                <Col lg={12} xs={24} className='title-wrapper'>
                  <Row justify='end' gutter={[0, 15]} align='middle'>
                    <Col
                      xxl={19} xl={20} lg={20} md={22} sm={22} xs={22}
                      dangerouslySetInnerHTML={{ __html: title && title }}
                    />
                    <Col xxl={19} xl={20} lg={20} md={22} sm={22} xs={22}>
                      <Row>
                        <Col>
                          <Button
                            className='cmt-button secondary'
                            type='primary'
                            size='large'
                            onClick={scrollToElement}
                          >
                            Contáctate con nosotros
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} xs={24} className='image-container'>
                  <div
                    className='image-content'
                    style={{ backgroundImage: `url(${image && imageUrlReplace(image.sourceUrl)})` }}
                    title={image && image.title}
                  />
                </Col>
                <Col
                  xxl={14} xl={17} lg={18} xs={20}
                  className="mt-lg-60 mt-md-40 mt-sm-40 mt-xs-40 mb-lg-50 mb-md-40 mb-sm-40 mb-xs-40"
                >
                  <h3 className="text-center mb-0">{subtitle && subtitle}</h3>
                </Col>
              </Row>
              <Row justify="center" className="cards-wrapper">
                <Col xxl={19} xl={20} lg={20} xs={22}>
                  {servicesCards()}
                </Col>
              </Row>
              <Row
                id="form"
                justify="center"
                className="form-wrapper pt-lg-100 pt-md-60 pt-sm-60 pt-xs-60 mb-lg-60"
              >
                <Col xxl={19} xl={20} lg={20} xs={24}>
                  <Row justify="center">
                    <Col lg={24} xs={20}>
                      <h4 className="text-center mb-lg-50 mb-md-20 mb-sm-20 mb-xs-20">Si necesitas  contactarte con nosotros</h4>
                    </Col>
                  </Row>
                  {
                    type === 'form' ?
                    <Row justify="center" className="contact-hubspot-container">
                      <Col lg={24} xs={24}>
                        <h3 className="text-center">Envíanos un <italic>mensaje</italic></h3>
                        <p className="text-center">Estamos disponibles para resolver cualquier duda que tengas. <br/> Completa el formulario y un ejecutivo se pondrá en contacto contigo en menos de 24 horas (hábiles).</p>
                      </Col>
                      <Col lg={20} xs={24}>
                        <div id="hubspotForm" />
                      </Col>
                    </Row>
                  :
                    <Row justify="center" className="contact-hubspot-container">
                      <Col lg={24} xs={24}>
                        <h3 className="text-center">¡Gracias por contactarnos!</h3>
                        <p className="text-center">Un ejecutivo se pondrá en contacto contigo en menos de 24 horas (hábiles).</p>
                      </Col>
                      <Col className="mt-30">
                        <Link to='/'>
                          <Button className='cmt-button primary' type='primary' size='large'>
                            Volver al Home
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default ServicesLanding;

export const postQuery = graphql`
  query GET_SERVICE_LANDING($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`